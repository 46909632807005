import { $, $$ } from '../../helper/utils';
import SiteSearch from './search';

export default () => {
  const searchEl = $('.site-search');

  if (searchEl === null) {
    return;
  }

  const searchMobileEl = $('.mobile-site-search');
  const search = new SiteSearch(searchEl);
  const mobileSearch = new SiteSearch(searchMobileEl);
  const openSearch = $('.open-site-search');
  const openMobileSearch = $('.open-mobile-site-search');
  const headerNavBtns = $$('.header-nav .nav-icon:not(.open-site-search), .open-site-search > span');

  openSearch.addEventListener('click', function () {
    const input = searchEl.querySelector('input');

    if (searchEl.classList.contains('show')) {
      searchEl.classList.remove('show');
      searchEl.setAttribute('aria-hidden', true);
      this.classList.remove('primary');

      if (input !== null) {
        input.blur();
      }

      TweenMax.to(headerNavBtns, 0.2, { autoAlpha: 1 });
    } else {
      searchEl.classList.add('show');
      searchEl.setAttribute('aria-hidden', false);
      this.classList.add('primary');

      TweenMax.to(headerNavBtns, 0.225, { autoAlpha: 0 });

      if (input !== null) {
        input.focus();
      }
    }
  }, { passive: true });

  let searchTween;
  openMobileSearch.addEventListener('click', () => {
    searchTween = TweenMax.to(searchMobileEl, 0.275, {
      xPercent: 0,
      autoAlpha: 1,
      pointerEvents: 'auto',
      ease: Sine.easeOut,
      startAt: {
        xPercent: -100,
      },
    });
  }, { passive: true });

  $('.close-mobile-search').addEventListener('click', () => {
    const input = searchMobileEl.querySelector('input');

    if (input !== null) {
      input.blur();
    }

    searchTween.reverse();
  }, { passive: true });

  // hide results on click outside
  window.addEventListener('click', (e) => {
    const input = searchEl.querySelector('input');

    if (e.target !== openSearch && e.target !== input && searchEl.classList.contains('show')) {
      searchEl.classList.remove('show');
      searchEl.setAttribute('aria-hidden', true);

      if (input !== null) {
        input.blur();
      }

      TweenMax.to(headerNavBtns, 0.2, { autoAlpha: 1 });
    }
  }, { passive: true });
};
