import { $, delegate, throttle, offset } from '../helper/utils';
import { MOBILMEDIA } from '../helper/constants';

const showMenuBtn = $('.show-menu');
const nav = $('.desktop-nav');
const mobileNav = $('.mobile-nav');
const closeMobileNav = $('.close-mobile-menu');
const navWrapper = $('.nav-wrapper');
const navBar1 = $('#header .bar1');
const navBar2 = $('#header .bar2');
const navBar3 = $('#header .bar3');
const header = $('#header');
const headerHeight = header !== null ? header.offsetHeight : 0;
const treshold = headerHeight * 3;
let lastScroll = 0;
let smallHeader = false;
let timeout = null;
let navOpen = false;

const desktopTl = new TimelineLite({
  paused: true,
  onComplete() {
    navOpen = true;
    nav.setAttribute('aria-hidden', false);
  },
  onReverseComplete() {
    navOpen = false;
    nav.setAttribute('aria-hidden', true);
  },
});

const mobileTl = new TimelineLite({
  paused: true,
  onComplete() {
    navOpen = true;
    mobileNav.setAttribute('aria-hidden', false);
  },
  onReverseComplete() {
    navOpen = false;
    mobileNav.setAttribute('aria-hidden', true);
  },
});

const hideHeader = (top) => {
  if ((navOpen === true && top > headerHeight && smallHeader === true) || smallHeader === false) {
    return;
  }

  smallHeader = false;
  clearTimeout(timeout);
  timeout = null;
  requestAnimationFrame(() => {
    header.classList.remove('small');
  });
};

const scrollCheck = throttle(() => {
  const top = window.pageYOffset || document.documentElement.scrollTop;

  if (top < treshold) {
    if (top <= headerHeight || (top > lastScroll && smallHeader === true)) {
      hideHeader(top);
    }

    lastScroll = top;
    return;
  }

  // scroll up show
  if (top < (lastScroll - 20) && smallHeader === false) {
    smallHeader = true;
    timeout = setTimeout(() => {
      requestAnimationFrame(() => {
        header.classList.add('small');
      });
    }, 300);

    // scroll down hide
  } else if (top > (lastScroll + 20)) {
    hideHeader(top);
  }

  lastScroll = top;
}, 150);

export default () => {
  if (showMenuBtn === null) {
    return;
  }

  const backdrop = nav.querySelector('.backdrop');

  // set reference to either timline depending on the viewport
  // makes it easier to handle which nav to control
  let activTl = MOBILMEDIA.matches ? mobileTl : desktopTl;

  desktopTl.set(nav, {
    pointerEvents: 'auto',
    visibility: 'inherit',
  })
    .to(navBar3, 0.175, {
      scaleX: 0,
      ease: Sine.easeOut,
    })
    .to(navBar1, 0.225, {
      rotation: -45,
      y: 8,
      ease: Sine.easeOut,
    })
    .to(navBar2, 0.225, {
      rotation: 45,
      ease: Sine.easeOut,
    }, '-=0.225')
    .to(backdrop, 0.275, {
      opacity: 0.6,
      ease: Sine.easeOut,
    }, '-=0.275')
    .to(navWrapper, 0.275, {
      yPercent: 0,
      ease: Sine.easeOut,
      startAt: {
        yPercent: -100,
        y: 0,
      },
    }, '-=0.275');

  mobileTl.to(mobileNav, 0.275, {
    xPercent: 0,
    autoAlpha: 1,
    pointerEvents: 'auto',
    ease: Sine.easeOut,
    startAt: {
      xPercent: -100,
    },
  });

  // open nav on menu button lick
  showMenuBtn.addEventListener('click', () => {
    if (navOpen === true) {
      activTl.reverse();

      return;
    }

    activTl.play();

    // disable page scroll for mobile view
    if (MOBILMEDIA.matches) {
      requestAnimationFrame(() => document.documentElement.classList.add('noscroll'));
    }
  }, { passive: true });

  // close desktop nav on backdrop click
  backdrop.addEventListener('click', () => {
    if (navOpen === true) {
      desktopTl.reverse();
    }
  }, { passive: true });

  // close mobile nav
  closeMobileNav.addEventListener('click', () => {
    if (navOpen === true) {
      mobileTl.reverse();
      requestAnimationFrame(() => document.documentElement.classList.remove('noscroll'));
    }
  }, { passive: true });

  // handle the slide in/out of mobile subnavs
  delegate(mobileNav, 'click', '.has-sub, .mobile-nav-prev', function (e) {
    e.preventDefault();

    // slide out current subnav
    if (this.classList.contains('mobile-nav-prev')) {
      const sub = this.parentElement;
      TweenMax.to(sub, 0.2, {
        xPercent: 100,
        ease: Sine.easeIn,
        startAt: {
          x: 0,
          xPercent: 0,
        },
        onComplete() {
          TweenMax.set(sub, { clearProps: 'all' });
        },
      });

      return;
    }

    const sub = this.nextElementSibling;

    // next child isn't a subnav
    if (sub.classList.contains('mobile-subnav') === false) {
      return;
    }

    // slide in subnav
    TweenMax.to(sub, 0.275, {
      xPercent: 0,
      visibility: 'inherit',
      ease: Sine.easeOut,
      startAt: {
        x: 0,
        xPercent: 100,
      },
    });
  });

  // change active nav on viewport change
  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      // if desktop nav is already open then open mobile nav and the same for closed state
      if (desktopTl.progress() === 1) {
        mobileTl.progress(1);
        requestAnimationFrame(() => document.documentElement.classList.add('noscroll'));
      } else {
        mobileTl.progress(0);
      }

      activTl = mobileTl;
    } else {
      // if mobile nav is already open then open desktop nav and the same for closed state
      if (mobileTl.progress() === 1) {
        desktopTl.progress(1);
        requestAnimationFrame(() => document.documentElement.classList.remove('noscroll'));
      } else {
        mobileTl.progress(0);
      }

      activTl = desktopTl;
    }
  });

  window.addEventListener('scroll', scrollCheck);
};
